import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p">{`The Winner’s of our 2018 Pre Open are…`}</strong></p>
    <p><strong parentName="p">{`Men’s RX:  Women’s RX:  Men’s Scaled:  Women’s Scaled:   Masters (over
50): `}</strong></p>
    <p><strong parentName="p">{`1-Dillon P      1-Ryan D          1-Nick J            1-Christy M    
       Men’s 1st Place- Ed L`}</strong></p>
    <p><strong parentName="p">{`2-Eric W        2-Kelley G       2-Craig M         2-Erika C      
 Women’s 1st Place- Lou S`}</strong></p>
    <p><strong parentName="p">{`3-Bill M          3-Alli J             3-Matt S            3-Erin B`}</strong></p>
    <p><strong parentName="p">{`*`}{`Congrats to all our winners and thanks to all that competed! `}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      